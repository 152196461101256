import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import StatisticNav from '../components/page_statistic';

// 统计导航页面

const NavStatisticPage = () => (
    <SiteLayout>
        <SEO title="NavStatistic" />
        <StatisticNav></StatisticNav>
    </SiteLayout>
)

export default NavStatisticPage;
