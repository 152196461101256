import React from 'react';
import { Link } from 'gatsby';
import { Button, Space } from 'antd';
import {
    BarChartOutlined,
} from '@ant-design/icons';

// import './homepage.css';

// 首页 新建购买记录，查询修改购买记录导航按钮

const StatisticNav = () => {
    return (
        <div className="home-page-div">
            <Space direction="vertical" size="large">
                <Link className="nav-link" to="/statisticrecords">
                    <Button type="primary" size="large" icon={<BarChartOutlined />}>销售统计</Button>
                </Link>
            </Space>
        </div>
    )
}

export default StatisticNav;
